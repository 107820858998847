@import '../../site/utilities/vw-calc';

.header {
    width: 100%;
    z-index: 100000;
    position: fixed;
    top: 0;
    left: 0;
    //background-color: transparent;
    background-color: #F5F8FF;;
    transition: all .3s ease;
    font-family: "Roboto", "Avenir Next", "Helvetica", Arial, sans-serif;

    .link_service{
        color:$color-blue-200;
        display: flex;
        align-items: center;
        img{
            background-color: $color-blue-200;
            padding: 2px;
            margin-right: 3px;
        }
    }

    .login_link {
        color: $color-grey-40;
    }

    &_shadow {
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
    }

    &__con {
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        padding: 20px 0;
        max-width: calc(100% - 4.167vw);
        height: 80px;

        @media(max-width: $desktop-l-break) {
            max-width: calc(100% - 60px);
        }

        @media(max-width: $mobile-break) {
            max-width: calc(100% - 32px);
            padding: 0;
        }

        & .header-mobile-ctaBtn{
            height: 32px;
            font-size: 12px;
            line-height: normal;
            border-radius: 5px;
            max-width: 130px;
        }
    }

    &__home {
        width: 132px;
        height: auto;

    }

    &__home-internal {
        @media(max-width: $tablet-break) {
            display: none;
        }
    }

    .spacer {
        @media(max-width: $mobile-break) {
            display: none;
        }
    }

    &__logo {
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    &__item-con {
        margin: 0 0 0 40px;
        padding: 0;
    }

    &__item,
    &__item a {
        display: flex;
        align-items: center;

        &:hover {
            text-decoration: none;
        }
    }

    &__item {
        list-style: none;
        width: max-content;
        position: relative;
        margin-right: 14px;
        padding: 12px 16px;
        cursor: pointer;

        &__primary_button {
            a {
                border: none;
                height: 44px;
                padding: 0 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                color: #fff!important;
                background: #2979ff;
            }
            & + .header__item_divider {
                display: none;
            }
        }

        &__login {
            a {
                border: none;
                height: 44px;
                padding: 0 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                color: $color-blue-new!important;
                background: transparent;
            }

            & + .header__item_divider {
                display: none;
            }
        }

        @media(max-width: $mobile-break) {
            flex-direction: column;
            align-items: flex-start;
            margin-right: 8px;
        }

        a {
            line-height: 2.25;
        }

        &_parent {
            position: relative;
        }
    }

    &__item_child {
        display: none;

        &.open {
            display: block;
        }
    }

    &__menu {
        align-items: center;
        margin: 0;

        &_desktop {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            @media(max-width: $tablet-break) {
                display: none;
            }

            .header__item-con {
                display: flex;
                align-items: center;

                .header__item {
                    a:not(.text_blue):not(.login_link):not(.text_white) {
                        color: $color-dark;
                    }

                    .text_blue,
                    .login_link {
                        margin-right: 0;
                    }

                    &_divider {
                        list-style: none;
                        height: 16px;
                        width: 1px;
                        margin: 0 16px 0 0;
                        background: #E4E5EC;
                    }
                }
            }

            //.header__item_parent:hover ~ .header__item_child,
            .header__item_child.open {
                display: block;
                position: absolute;
                top: 100%;
                left: 0;
                width: max-content;
                padding: 0;
                background: $color-white;
                box-shadow: 0 4px 4px rgba(0, 0, 0, 0.125);

                & > .header__item {
                    width: 100%;
                    padding: 0;

                    a {
                        width: 100%;
                        height: 100%;
                        padding: 12px 16px;
                    }

                    a:hover {
                        background: $color-light-grey-40;
                    }
                }
            }
        }

        &_mobile {
            display: none;

            @media(max-width: $tablet-break) {
                display: block;
            }

            .header__item-con-wrapper {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100vh;
                transition: transform .25s ease;
                padding: 16px;

                &_left {
                    background: $color-white;
                    transform: translateX(-100%);

                    &.open {
                        transform: translateX(0);
                    }

                }

                &_right {
                    background: #001449;
                    transform: translateX(100%);

                    &.open {
                        transform: translateX(0);
                    }

                    .header__item a:not(.text_blue),
                    .header__button-close {
                        color: $color-white;
                    }
                }

                & .top-section{
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 50px;
                }

                & .middle-section{
                    list-style: none;
                    margin: 0;
                    padding: 0 0 16px 0;
                    border-bottom: 1px solid #e7eaee;
                    & .middle-section-item{
                        padding: 16px 0;
                        display: flex;
                        justify-content: space-between;
                        margin: 0;
                        & > .title{
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 1.5;
                            color: #111418;
                        }
                        & .icon-holder:before{
                            font-weight: bold;
                        }
                    }
                    & .middle-section-subitems{
                        display: none;
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        & .middle-section-subitems-item{
                            padding: 12px 8px;
                            display: flex;
                            justify-content: space-between;
                            & > .title{
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 1.7;
                                color: #435160;
                            }
                            & span.v4-icon{
                                color: #2979FF;
                            }
                        }
                    }
                }

                & .photographer-section{
                    margin: 16px 0 32px;
                    padding: 16px 0 32px;
                    border-bottom: 1px solid #e7eaee;
                    & a{
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 1.5;
                        color: #002566;
                        & .header__icon_rvstore{
                            display: inline-block;
                            position: relative;
                            top: 2px;
                        }
                    }
                }
                & .bottom-section{
                    display: flex;
                    flex-direction: column;
                    & .login-holder,& .dashboard-holder{
                        margin-bottom: 32px;
                        & a{
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 1.5;
                            color: #2979ff;
                        }
                    }

                    @media (max-width: $mobile-break) {
                        & .login-holder {
                            text-align: center;
                        }
                    }

                    & .menu-holder{
                        margin-bottom: 32px;
                        & a{
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 1.5;
                        }
                    }
                    & .cta-holder{
                        & a{
                            max-width: 100%;
                        }
                    }
                    & .dashboard-holder{
                        & a{
                            color: #002566;
                            & i{
                                position: relative;
                                top: 5px;
                            }
                        }
                    }
                }
            }

            .header__item-con {
                margin: 20px 0;
            }

            .header__item_parent {
                padding-bottom: 12px;
            }

            .header__item_child {
                padding: 0;
            }

            .header__item {
                font-size: 16px;
            }
        }

        &_mobile-inner {
            padding: 0;
            margin: 0;
        }
    }

    &__icon {
        &_menu,
        &_toggle {
            cursor: pointer;
        }

        &_menu {
            font-size: 24px;
        }

        &_rvstore {
            display: block;
            width: 16px;
            height: 16px;
            margin-right: 4px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &__button-close {
        position: absolute;
        top: 8px;
        left: 0;
        font-size: 20px;
    }

    &.light {
        background-color: $color-white;
        box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.06);

        .login_link {
            color: $color-dark;
        }
        .header__item_divider {
            background: $color-dark;
        }
        .cta_link {
            color: $color-blue-100;
        }

        .header__item__login a {
            border: none;
            background: transparent!important;
            color: $color-blue-new!important;
            height: 44px;
            padding: 0 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
        }
    }
    & .getStarted{
        color: #FFF!important;
    }


    & .header-overlay{
        display: none;
        position: absolute;
        width: 100vw;
        height: 100vh;
        z-index: 100001;
        background-color: rgba(0, 0, 0, 0.1);
        &.active{
            display: block;
        }
    }

    & .header__item.show-out{
        background: #FFF;
        z-index: 100002;
        border-radius: 5px;
        box-shadow: 0 5px 16px 5px rgba(41, 121, 255, 0.05), 0 5px 8px 0 rgba(41, 121, 255, 0.05);
    }

    & .header__item .icon-chevron-up{
        color: #2979FF!important;
    }

    &__child-mega{
        display: none;
        position: fixed;
        z-index: 100002 ;
        top: 60px;
        left: vw(120px);
        right: vw(120px);
        width: calc(100vw - 16.6vw);
        cursor: default;
        border-radius: 5px;

        &.open{
            display: flex;
        }

        & .link-item{
            width: vw(280px);
            height: vw(44px);
            padding: 0 vw(12px);
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 5px;
            box-shadow: 0 5px 16px 5px rgba(41, 121, 255, 0.05), 0 5px 8px 0 rgba(41, 121, 255, 0.05);
            background-color: #FFF;
            & span:first-child{
                font-size: vw(14px);
                font-weight: 600;
                line-height: 1.71;
            }
            & span:last-child{
                font-size: vw(14px);
                color: #2979FF;
            }
        }

        & .left-holder{
            flex: 0 0 56%;
            background-color: #F6F9FF;
            padding: vw(24px) vw(44px);
            display: flex;
            flex-direction: column;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            & .title{
                font-size: vw(20px);
                font-weight: normal;
                line-height: 1.6;
                color: #111418;
                margin: 0 0 vw(44px) 0;
            }
            & .links-holder{
                display: flex;
                flex-wrap: wrap;
                & .link-item-holder{
                    margin-bottom: vw(24px);
                    margin-right: vw(24px);
                    &:nth-child(2n){
                        margin-right: 0;
                    }
                }
            }
        }
        & .right-holder{
            flex: 0 0 44%;
            background-color: #EBF2FF;
            padding: vw(24px) vw(44px);
            display: flex;
            flex-direction: column;
            align-items: center;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            & .title{
                font-size: vw(20px);
                line-height: 1.6;
                color: #2979ff;
                margin: 0 0 vw(24px) 0;
            }
            & .img-holder{
                width: vw(240px);
                height: auto;
                margin-bottom: vw(24px);
                & img{
                    width: 100%;
                    height: 100%;
                }
            }
            & .desc{
                font-size: vw(14px);
                width: vw(245px);
                line-height: 1.71;
                text-align: center;
                color: #111418;
                margin: 0 0 vw(24px) 0;
            }
            & .cta{
                color: #FFF!important;
            }
        }
        & .full-holder{
            flex: 0 0 100%;
            background-color: #F6F9FF;
            padding: vw(40px);
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            border-radius: 5px;
            & .link-item-holder{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: vw(24px);
                margin-right: vw(24px);
                &:nth-child(n+5){
                    margin-bottom: 0;
                }
                & .img-holder{
                    width: vw(221px);
                    height: vw(143px);
                    margin-bottom: vw(16px);
                    & img{
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                    }
                }
                & .link-item{
                    width: vw(250px);
                }
            }
        }


    }


}