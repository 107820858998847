.footer {
  width: 100%;
  justify-content: space-between;
  padding: 0;
  margin-top: 0;
  position: relative;

  &.footer_grey {
    background: #202020;
  }

  &.footer_small {
    .main {
      width: 100%;
      padding: vw(24px) vw(24px) 0 vw(24px);

      @media (max-width: $desktop-l-break) {
        padding: 24px 24px 0 24px;
      }

      @media(max-width: $mobile-break) {
        padding: 30px 20px 0px;
        background: #202020;
      }

      .link {
        width: 100%;
        align-items: center;
        list-style: none;

        @media(max-width: $mobile-break) {
          flex-direction: row;
        }

        .link-img:not(:last-of-type) {
          @media(max-width: $mobile-break) {
            margin-bottom: 0px;
          }
        }

        .link-img {
          margin-right: vw(40px);

          @media (max-width: $desktop-l-break) {
            margin-right: 40px;
          }

          &.roomvu-logo {
            @media(max-width: $mobile-break) {
              display: none;
            }
          }

          &.roomvu-academy {
            img {
              height: 25px;
            }
          }

          @media(max-width: $mobile-break) {
            width: 50%;
          }
        }
      }
    }

    .copyright {
      background: #181818;
      padding: vw(24px) vw(24px) 0 vw(24px);
      border-top: 1px solid rgba($color-white, 0.2);

      @media(max-width: $desktop-l-break) {
        padding: 24px 24px 0 24px;
      }
      @media(max-width: $mobile-break) {
        flex-direction: column;
        background: #181818;
        margin: 0;
        width: 100%;
        padding: 20px;
        min-height: 180px;
      }

      .copy {
        flex-shrink: 0;
        margin-right: vw(64px);
        color: #95a5b6;

        @media(max-width: $desktop-l-break) {
          margin-right: 56px;
        }
        @media(max-width: $mobile-break) {
          margin-right: 0;
          order: 3;
          position: absolute;
          bottom: 45px;
          left: 20px;
        }

        span {
          @media(max-width: $mobile-break) {
            display: none;
          }
        }
      }

      .center-content {
        margin-bottom: 0;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        @media(max-width: $mobile-break) {
          flex-wrap: wrap;
        }

        .link {
          @media(max-width: $mobile-break) {
            width: 50%;
            margin-bottom: 16px;
          }
        }
      }

      .social {
        margin-left: vw(64px);

        @media(max-width: $desktop-l-break) {
          margin-left: 56px;
        }

        @media(max-width: $mobile-break) {
          margin-left: 0;
          order: 2;
          position: absolute;
          right: 20px;
          bottom: 28px;
        }

        &__item {
          width: vw(32px);
          height: vw(32px);
          border-radius: 10px;
          background: #ffffff;

          @media(max-width: $desktop-l-break) {
            width: 32px;
            height: 32px;
          }

          @media(max-width: $mobile-break) {
            width: 25px;
            height: 25px;
            font-size: 14px;
            border-radius: 10px;
            background: #ffffff;
            padding-top: 2px;
          }

          a {
            color: #000000;
          }
        }
      }
    }

    .footer__button {
      top: vw(16px);
      right: 7%;
      background: #2979ff;
      border-radius: 50%;
      width: vw(35px);
      height: vw(35px);
      color: #ffffff;
      font-size: vw(14px);
      text-align: center;
      font-weight: normal;
      padding-top: vw(5px);

      @media(max-width: $desktop-l-break) {
        top: 16px;
        width: 35px;
        height: 35px;
        font-size: 14px;
        padding-top: 5px;
      }

      @media(max-width: $mobile-break) {
        top: 55px;
      }
    }
  }

  a:not(.footer__button) {
    color: $color-white;

    &:hover {
      text-decoration: none;
    }
  }

  .main {
    flex-wrap: wrap;

    &__col {
      width: 25%;

      @media(max-width: $tablet-break) {
        width: 50%;
        margin-bottom: 40px;
      }
      @media(max-width: $mobile-break) {
        width: 100%;
        margin-bottom: 32px;
      }

      &_half {
        @media(max-width: $mobile-break) {
          width: 50%;
        }
      }

      &_listing {
        flex-direction: column;
        @media(max-width: $mobile-break) {
          flex-direction: row;
          flex-wrap: wrap;
        }
      }

      &_social {
        @media(max-width: $mobile-break) {
          max-width: calc(480px - (16px * 2));
          margin: auto auto auto 0;
        }
        @media(max-width: 480px) {
          margin: auto;
        }

        .main__item {
          @media(max-width: 480px) {
            text-align: center;
          }
        }
      }
    }

    &__header {
      font-weight: 500;
      text-transform: uppercase;
      color: $color-white;
      margin-bottom: 20px;
    }

    &__item {
      color: #eceff1;
      margin-bottom: 14px;
      line-height: 2;

      &_img {
        width: auto;
        height: 16px;

        @media(max-width: $mobile-break) {
          width: 70%;
          height: auto !important;
        }
      }

      &.hidden_mb {
        @media(max-width: $tablet-break) {
          display: none;
        }
      }
    }

    &__item:hover,
    &__item_img:hover {
      cursor: pointer;
    }
  }

  .social {
    padding-left: 0;
    @media(max-width: 480px) {
      justify-content: space-between;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 100%;
      color: $color-white;
      background: $color-grey-60;

      &:hover {
        background: $color-blue-100;
      }

      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }
  }

  &__title {
    margin-bottom: 20px;
  }

  &__item {
    line-height: 2;
  }

  &__submit {
    visibility: hidden;

    &__icon {
      display: block;
      width: 14px;
      height: 14px;
      background-image: url('/img/footer-v3/arrow.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      visibility: visible;
    }
  }

  &__social {
    &_item {
      display: block;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-grey-40;

      &:hover {
        background: $color-blue-100;
      }

      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }
  }

  .form {
    position: relative;

    &__input {
      width: 100%;
      max-width: 270px;
      height: 48px;
      border-radius: 4px;
    }

    button {
      position: absolute;
      right: 0;
      border: none;
      box-shadow: none;
      background: transparent;
      padding: 14px;
      cursor: pointer;
    }

    &__button {
      color: $color-grey-80;
    }
  }

  .copyright {
    color: $color-white;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    font-size: 30px;
    color: $color-blue-80;
    position: absolute;
    top: -25px;
    left: calc(50% - 25px);
    text-decoration: none !important;

    &:hover {
      opacity: 0.9;
    }

    i {
      background-color: #2979ff;
      color: #fff;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: rem(24px);
    }

    @media(max-width: $tablet-break) {
      font-size: 24px;

    }
  }

  &__landing {
    padding: 1.5rem 0;

    .social {
      margin-bottom: 0;

      &__item {
        width: 2.22222222vw;
        height: 2.22222222vw;
        border-radius: 10px;
        background: $color-white;
        @media(max-width: $mobile-break) {
          width: 5.22222222vw;
          height: 5.22222222vw;
        }

        a {
          color: $color-black;
        }

        &:hover {

        }
      }
    }

    .copy {
      color: #95a5b6;
      @media(max-width: $mobile-break) {
        margin-top: 1rem;
      }

    }

    .content_row {
      @media(max-width: $mobile-break) {
        display: block;
      }
    }


  }

}