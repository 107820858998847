// Asma's styleguide - Jul 2020
@import '../site/utilities/vw-calc';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap');

@font-face {
	font-display: swap;
	font-family: 'Avenir Next';
	src: url("/fonts/avenir-next/AvenirNext-Regular.woff2") format('woff2'),
		 url("/fonts/avenir-next/AvenirNext-Regular.woff") format('woff'),
		 url("/fonts/avenir-next/AvenirNext-Regular.ttf") format('truetype');
	font-weight: 400;
}
@font-face {
	font-display: swap;
	font-family: 'Avenir Next';
	src: url("/fonts/avenir-next/AvenirNext-Medium.woff2") format('woff2'),
		 url("/fonts/avenir-next/AvenirNext-Medium.woff") format('woff'),
		 url("/fonts/avenir-next/AvenirNext-Medium.ttf") format('truetype');
	font-weight: 500;
}
@font-face {
	font-display: swap;
	font-family: 'Avenir Next';
	src: url("/fonts/avenir-next/AvenirNext-DemiBold.woff2") format('woff2'),
		 url("/fonts/avenir-next/AvenirNext-DemiBold.woff") format('woff'),
		 url("/fonts/avenir-next/AvenirNext-DemiBold.ttf") format('truetype');
	font-weight: 700;
}

// video font
@font-face {
	font-display: swap;
	font-family: 'DINCond';
    src: url("/fonts/DINCond-Medium.woff2") format("woff2"),
         url("/fonts/DINCond-Medium.woff") format("woff"),
         url("/fonts/DINCond-Medium.ttf") format("truetype"),
         url("/fonts/DINCond-Medium.otf") format("opentype");
}

//////// base style

* {
	box-sizing: border-box;
	background-size: cover;
	background-position: center;
	object-fit: cover;
	object-position: center;
}

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	background: #fff;
    font-family: $typeface-avenir;

    font-size: vw(14px);

    @media(max-width: $desktop-l-break) {
        font-size: 14px;
    }
}

p {
    font-size: vw(14px);

    @media(max-width: $desktop-l-break) {
        font-size: 14px;
    }
}

button,
input,
*::placeholder {
    font-family: $typeface-avenir;
}

button {
    outline: none;
}

form label {
    font-weight: 400;
}

.font_accent {
    font-family: 'Montserrat', 'Avenir Next', Helvetica, Arial, sans-serif;
    font-weight: 500;
}

.margin-top {
    margin-top: vw(80px);

    @media (max-width: $desktop-l-break) {
		margin-top: 80px;
	}
    @media (max-width: $desktop-s-break) {
		margin-top: 60px;
	}
    // @media(max-width: $tablet-break) {
    //     margin-top: 40px;
    // }
    // @media(max-width: $mobile-break) {
    //     margin-top: 20px;
    // }

    &_half {
        margin-top: vw(40px);

        @media (max-width: $desktop-l-break) {
            margin-top: 40px;
        }
        @media (max-width: $desktop-s-break) {
            margin-top: 30px;
        }
        // @media(max-width: $tablet-break) {
        //     margin-top: 20px;
        // }
        // @media(max-width: $mobile-break) {
        //     margin-top: 10px;
        // }
    }

    &_30 {
        margin-top: vw(30px);

        @media (max-width: $desktop-l-break) {
            margin-top: 30px;
        }
        @media (max-width: $desktop-s-break) {
            margin-top: 20px;
        }
        // @media(max-width: $tablet-break) {
        //     margin-top: 10px;
        // }
        // @media(max-width: $mobile-break) {
        //     margin-top: 5px;
        // }
    }
}

.margin-bottom {
	margin-bottom: vw(80px);

    @media (max-width: $desktop-l-break) {
		margin-bottom: 80px;
	}
    @media (max-width: $desktop-s-break) {
		margin-bottom: 60px;
	}
    // @media(max-width: $tablet-break) {
    //     margin-bottom: 40px;
    // }
    // @media(max-width: $mobile-break) {
    //     margin-bottom: 20px;
    // }

    &_60 {
        margin-bottom: rem(60px);

        @media (max-width: $desktop-l-break) {
            margin-bottom: 60px;
        }
        @media (max-width: $desktop-s-break) {
            margin-bottom: 40px;
        }
        // @media (max-width: $tablet-break) {
        //     margin-bottom: 28px;
        // }
         @media (max-width: $mobile-break) {
             margin-bottom: 100px;
         }
    }

    &_half {
        margin-bottom: vw(40px);

        @media (max-width: $desktop-l-break) {
            margin-bottom: 40px;
        }
        @media (max-width: $desktop-s-break) {
            margin-bottom: 30px;
        }
        // @media(max-width: $tablet-break) {
        //     margin-bottom: 20px;
        // }
        // @media(max-width: $mobile-break) {
        //     margin-bottom: 10px;
        // }
    }

    &_30 {
        margin-bottom: vw(30px);

        @media (max-width: $desktop-l-break) {
            margin-bottom: 30px;
        }
        @media (max-width: $desktop-s-break) {
            margin-bottom: 20px;
        }
        // @media(max-width: $tablet-break) {
        //     margin-bottom: 10px;
        // }
        // @media(max-width: $mobile-break) {
        //     margin-bottom: 5px;
        // }
    }
}

.padding-top {
    padding-top: vw(80px);

    @media (max-width: $desktop-l-break) {
        padding-top: 80px;
    }
	@media (max-width: $mobile-break) {
		padding-top: 40px;
    }

    &_half {
        padding-top: vw(40px);

        @media (max-width: $desktop-l-break) {
            padding-top: 40px;
        }
        @media (max-width: $mobile-break) {
            padding-top: 20px;
        }
    }
}

.padding-bottom {
    padding-bottom: vw(80px);

    @media (max-width: $desktop-l-break) {
        padding-bottom: 80px;
    }
	@media (max-width: $mobile-break) {
		padding-bottom: 40px;
    }

    &_half {
        padding-bottom: vw(40px);

        @media (max-width: $desktop-l-break) {
            padding-bottom: 40px;
        }
        @media (max-width: $mobile-break) {
            padding-bottom: 20px;
        }
    }
}

.mid-width {
    margin-right: auto;
    margin-left: auto;
    width: 85%;
    max-width: 1640px;

	@media (max-width: $mobile-break) {
        margin-right: 20px;
        margin-left: 20px;
        width: calc(100% - 40px);
	}
}

.container-width {
    max-width: 800px;
    margin: auto;

    @media(max-width: $mobile-break) {
        width: calc(100% - 40px);
    }
}

.content {
    &_row,
    &_col {
        display: flex;
    }

    &_col {
        flex-direction: column;
    }
}

//////// color and typograpies

.text {
    &_uppercase {
        text-transform: uppercase;
    }

    &_center {
        text-align: center;
    }

    &_white,
    &_white:hover {
        color: $color-white;
    }
    &_grey,
    &_grey:hover {
        color: $color-grey-80;
    }
    &_grey-200 {
        color: $color-grey-200;
    }
    &_grey-40 {
        color: $color-grey-40;
    }
    &_blue,
    &_blue:hover {
        color: $color-blue-100;
    }
    &_blue-80 {
        color: $color-blue-80;
    }
    &_blue-light {
        color: #90CAF9;
    }
    &_red {
        color: $color-red;
    }
    &_green {
        color: $color-green;
    }

    &_bold {
        font-weight: 600;
    }
    &_mid {
        font-weight: 500;
    }
    &_reg {
        font-weight: 400;
    }
    &_italic {
        font-style: italic;
    }
    &_underline {
        text-decoration: underline;
    }

    &__heading {
        &_bold {
            font-weight: 600;
            font-size: vw(32px);
            letter-spacing: vw(2px);

            @media(max-width: $desktop-l-break) {
                font-size: 32px;
                letter-spacing: 2px;
            }
        }
        &_demibold {
            font-weight: 500;
            font-size: vw(28px);
            letter-spacing: vw(1px);

            @media(max-width: $desktop-l-break) {
                font-size: 28px;
                letter-spacing: 1px;
            }
        }
        &_medium {
            // font-weight: 400;
            font-size: vw(18px);
            letter-spacing: vw(0.5px);
            line-height: 1.75;

            @media(max-width: $desktop-l-break) {
                font-size: 18px;
                letter-spacing: 0.5px;
            }

            &.no-spacing {
                letter-spacing: normal;
            }
        }
    }

    &__paragraph {
        &_large {
            font-size: vw(18px);
            line-height: 1.75;
            letter-spacing: vw(1px);

            @media(max-width: $desktop-l-break) {
                font-size: 18px;
                letter-spacing: 1px;
            }
        }

        &_medium {
            font-size: vw(16px);
            line-height: 2;

            @media(max-width: $desktop-l-break) {
                font-size: 16px;
            }
        }

        &_small {
            font-size: vw(14px);
            line-height: 1.7;

            @media(max-width: $desktop-l-break) {
                font-size: 14px;
            }
        }
        &_xssmall {
            font-size: vw(12px);
            line-height: 1.5;

            @media(max-width: $desktop-l-break) {
                font-size: 12px;
            }
        }
    }
    &_black{
        color:$color-black-new;
    }
    &_red{
        color:$color-red !important;
    }
    &-large{
        font-size: 18px !important;
        line-height: 1.3 !important;
    }
}

.bg {
    &_blue-100 {
        background: #1565c0;
    }
    &_blue-80 {
        background: #1e88e5;
    }
    &_blue-60 {
        background: #42a5f5;
    }
    &_blue-40 {
        background: #90caf9;
    }
    &_blue-20 {
        background: #e3f2fd;
    }
    &_blue-10 {
        background: $color-blue-10;
    }

    &_gold-100 {
        background: #846432;
    }
    &_gold-80 {
        background: #b6915d;
    }
    &_gold-60 {
        background: #eac18b;
    }
    &_gold-40 {
        background: #F4E0C5;
    }
    &_gold-20 {
        background: #F8ECDC;
    }

    &_grey-100 {
        background: #102027;
    }
    &_grey-80 {
        background: #37474f;
    }
    &_grey-60 {
        background: #62727b;
    }
    &_grey-40 {
        background: #919CA2;
    }
    &_grey-20 {
        background: #B0B8BD;
    }

    &_light-grey-100 {
        background: #babdbe;
    }
    &_light-grey-80 {
        background: #eceff1;
    }
    &_light-grey-60 {
        background: #F1F3F5;
    }
    &_light-grey-40 {
        background: #F8F9FA;
    }
    &_light-grey-20 {
        background: #F9FAFA;
    }
    &_light-grey-10 {
        background: $color-light-grey-10;
    }

    &_white-border {
        background: #fff;
        border: 1px solid #edeef4;
    }

    &_blue-gradient {
        background: $color-blue-20;
        background: linear-gradient(0deg, $color-blue-40 0%, $color-blue-20 100%);
    }
}

.spacer {
    min-width: vw(32px);
    height: 100%;

    @media(max-width: $desktop-l-break) {
        min-width: 32px;
    }

    @media(max-width: $tablet-break) {
        min-width: 20px;
    }
}

.button {
    font-weight: 400;
    min-width: vw(240px);
    height: max-content;
    padding: vw(18px) vw(48px);
    border-radius: 3px;
    text-align: center;
    border: none;
    box-shadow: none;
    font-size: vw(14px);
    cursor: pointer;
    text-decoration: none;

    @media(max-width: $desktop-l-break) {
        min-width: 240px;
        padding: 18px 48px;
        font-size: 14px;
    }
    @media(max-width: $mobile-break) {
        width: auto;
        padding: 18px 32px;
    }

    &_blue {
        background: $color-blue-100;
        color: $color-white;
        flex-shrink: 0;
    }

    &_blue-outline {
        color: $color-blue-100;
        border: 1px solid $color-white-border;
        background: $color-white;
        flex-shrink: 0;
    }
    &_sm{
        border-radius: 20px !important;
        padding: 10px 20px !important;
    }
}

.icon {
    &_20 {
        display: block;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.mt-input_outlined {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: vw(540px);

    @media(max-width: $desktop-l-break) {
        max-width: 540px;
    }

    &.error {
        & input:focus,
        & textarea:focus {
            border: 2px solid $color-red;
        }

        & > label,
        & input:focus + label,
        & textarea:focus + label {
            color: $color-red;
        }
    }

    input,
    textarea {
        border: 1px solid #ddd;
        background: $color-white;
        padding: vw(16px);
        font-size: vw(18px);
        width: 100%;
        border-radius: 4px;
        transition: 0.2s ease;

        @media(max-width: $desktop-l-break) {
            padding: 16px;
            font-size: 18px;
        }
        @media(max-width: $mobile-break) {
            font-size: 16px;
        }

        &:focus {
            border: 2px solid $color-blue-100;
            padding: vw(15px) vw(16px);
            outline: none;
            width: 100%;

            @media(max-width: $desktop-l-break) {
                padding: 15px 16px;
            }
        }
    }

    &.active > label,
    input:focus + label,
    textarea:focus + label {
    // input:valid + label { // needs to add "valid" to detect if there're chars
        margin-top: vw(-9px);
        margin-left: vw(9px);
        color: $color-blue-100;
        font-size: vw(14px);
        padding: 0 vw(6px);
        background: white;

        @media(max-width: $desktop-l-break) {
            margin-top: -9px;
            margin-left: 9px;
            font-size: 14px;
            padding: 0 6px;
        }
    }

    label {
        position: absolute;
        margin: vw(16px);
        padding-right: 15%;
        background: white;
        left: 0;
        top: 0;
        transition: 0.2s ease;
        font-size: vw(18px);
        font-weight: 400;

        @media(max-width: $desktop-l-break) {
            font-size: 18px;
            margin: 16px;
        }

        @media(max-width: $mobile-break) {
            padding-right: 2%;
        }
    }

    &_icon {
        position: absolute;
        transform: translateY(30%);
        height: auto;
        right: vw(20px);
        top: 20%;
        cursor: pointer;

        @media(max-width: $desktop-l-break) {
            right: 20px;
        }

        &.visible {
            color: $color-blue-100;
        }
    }

    &__charcount {
        position: absolute;
        top: 100%;
        right: 0;
        margin: 4px;
        font-size: vw(12px);
        color: $color-grey-40;
        text-align: right;

        @media(max-width: $desktop-l-break) {
            font-size: 10px;
        }
    }
}

.mt-input_filled {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 540px;
    textarea,
    input[type="text"] {
        width: 100%;
        background: #eee;
        border: none;
        border-bottom: 1px solid #aaa;
        padding: 28px 16px 20px;
        font-size: 18px;
        border-radius: 2px 0 0;
        position: relative;
    }

    &.active,
    input:focus {
    // &:valid { // needs to add "valid" to detect if there're chars
        outline: none;
    }

    &.active > label,
    input:focus + label {
    // input:valid + label { // needs to add "valid" to detect if there're chars
        font-size: 14px;
        top: -20px;
        color: $color-blue-100;
        padding-right: 0;

        ~ .mt-input_filled__line-ripple:before,
        ~ .mt-input_filled__line-ripple:after {
            width: calc(50%);
            background: $color-blue-100;
            border-bottom: 0 // erase base border
        }
    }

    label {
        position: absolute;
        top: 0;
        left: 0;
        margin: 28px 16px 20px;
        font-size: 18px;
        line-height: 18px;
        transition: all .2s ease;
        background: #eee;
        padding-right: 15%;
        line-height: 1.25;

        @media(max-width: $mobile-break) {
            padding-right: 5%;
        }
    }
    .mt-input_filled__line-ripple {
        position: relative;
        display: block;
        width: 100%;

        &:before,
        &:after {
            content: '';
            height: 2px;
            width: 0;
            position: absolute;
            bottom: 0;
            background: $color-blue-100;
            transition: all .2s ease;
        }
        &:before {
            left: calc(50%);
        }
        &:after {
            right: calc(50%);
        }
        + label:after {
            display: block;
            content: '';
            position: absolute;
            top: 65px;
            opacity: 0;
        }
    }
}

.mt-input-radio {
    display: flex;
    align-items: center;

    &_hidden {
        display: none;

        &:checked + .mt-input-radio_color {
            border-color: $color-blue-100;

            &.color_grey-100 {
                border-color: $color-grey-100;
            }

            &.color_gold-100 {
                border-color: $color-gold-100;
            }
        }

        &:checked + .mt-input-radio_color {
            &:after {
                transform: scale(1);
                transition: all .2s cubic-bezier(.35, .9, .4, .9);
                opacity: 1;
            }
        }

        &:not(:checked) ~ .mt-input-radio__label {
            color: $color-light-grey-100;
        }
    }

    &_color {
        position: relative;
        display: block;
        float: left;
        margin-right: 10px;
        width: 28px;
        height: 28px;
        background: white;
        border: 2px solid $color-light-grey-100;
        border-radius: 100%;
        -webkit-tap-highlight-color: white;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: -4px;
            left: -4px;
            width: 35px;
            height: 35px;
            background: white;
            border-radius: 100%;
            z-index: -1;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 5px;
            left: 5px;
            width: 14px;
            height: 14px;
            border-radius: 100%;
            transform: scale(0);
            transition: all .2s ease;
            pointer-events: none;
            opacity: 0.08;
            background: $color-blue-100;
        }

        &.color_grey-100:after {
            background: $color-grey-100;
        }

        &.color_gold-100:after {
            background: $color-gold-100;
        }

        &:hover {
            &:after {
                transform: scale(3.6);
            }
        }
    }

    &__label {
        width: 100%;
        display: inline;
        vertical-align: middle;
        font-size: 18px;
        color: $color-grey-80;
    }
}

.badge {
    &_orange {
        color: $color-white;
        background: rgba(234, 193, 139, 0.8);
        border-radius: 100px;
    }
}

.mt-input-checkbox {
    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
        visibility: hidden;
        position: absolute;
        left: -9999px;

        & + label {
            position: relative;
            padding-left: calc(30px);
            margin-bottom: 0;
            cursor: pointer;

            @media(max-width: $mobile-break) {
                padding-left: calc(20px + 8px);
            }

            // checkbox outline
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                background: #fff;
            }

            // checkbox check icon
            &::after {
                font-family: 'rvicons-2020';
                content: '\e951';
                position: absolute;
                top: 5px;
                left: 3px;
                font-size: 16px;
                line-height: 0.8;
                transition: all .2s;
            }
        }
    }

    [type="checkbox"]:not(:checked) {
        & + label::after {
            opacity: 0;
            transform: scale(0);
        }
    }
    [type="checkbox"]:checked {
        & + label::after {
            opacity: 1;
            transform: scale(1);
        }
    }

    &_blue {
        [type="checkbox"]:not(:checked),
        [type="checkbox"]:checked {
            & + label::before {
                border: 1px solid #95a5b6;
            }

            & + label::after {
                color: #95a5b6;
            }
        }
    }

    &_gold {
        [type="checkbox"]:not(:checked),
        [type="checkbox"]:checked {
            & + label::before {
                border: 1px solid $color-gold-100;
            }

            & + label::after {
                color: $color-gold-100;
            }
        }
    }
}

.mt-input-selectbox {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 540px;
    border: 1px solid #ddd;
    background: $color-white;
    width: 100%;
    border-radius: 4px;
    transition: 0.2s ease;
    cursor: pointer;

    &.active {
        border: 2px solid $color-blue-100;
    }

    &_box {
        padding: 16px;
        font-size: 18px;

        &:focus,
        &.active {
            // border: 2px solid $color-blue-100;
            padding: 15px 16px;
            outline: none;
            width: 100%;
        }
    }

    &_label {
        position: absolute;
        top: 50%;
        bottom: 50%;
        transform: translateY(-50%);
        left: 16px;
        width: max-content;
        height: max-content;
        font-size: 18px;
        background: white;
        transition: all .2s ease;
    }

    &.active > .mt-input-selectbox_label {
        top: 0;
        left: 6px;
        padding: 4px;
        background: $color-white;
        color: $color-blue-100;
        font-size: 14px;
    }

    &_icon {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        transition: transform .2s ease;

        &.active {
            transform: translateY(-50%) rotate(180deg);
        }
    }

    &_option {
        width: 100%;
        max-width: 540px;
        margin: 0;
        padding: 0;
        background: $color-white;
        position: absolute;
        top: 104%;
        z-index: 1;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
        transform: scale(0) translateY(-55%);
        transition: transform .15s ease;

        &.active {
            transform: scaleY(1) translateY(0);
        }

        & > li {
            list-style: none;
            padding: 16px;

            &.disabled {
                color: $color-light-grey-100;
            }

            &:hover,
            &:checked,
            &:focus {
                background: $color-light-grey-80;
            }

            &.disabled:hover,
            &.disabled:checked,
            &.disabled:focus {
                background: transparent;
            }
        }
    }

    &__item {
        &.selected {
            background: $color-light-grey-80;
        }
    }
}

.mt-input-switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    border-radius: 24px;
    flex-shrink: 0;
    cursor: pointer;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    input.checked + .mt-input-switch__slider,
    input:checked + .mt-input-switch__slider {
        background-color: $color-blue-100;

        &::before {
            transform: translateX(16px); // switch-width - slider-width
        }
    }

    &__slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color-grey-40;
        border-radius: 24px;
        box-shadow: inset 0 2px 1px 0 rgba(0, 0, 0, 0.03);
        transition: .4s ease;

        &::before {
            position: absolute;
            content: "";
            width: 16px;
            height: 16px;
            left: 4px;
            bottom: 4px;
            border-radius: 50%;
            background: $color-white;
            transition: .4s ease;
        }
    }
}


.g-recaptcha iframe {
    object-fit: unset !important;
}

.drift-conductor-item iframe {
    object-fit: unset !important;
}