// margin
$margin-80: 80px;
$margin-50: 50px;
$margin-40: 40px;

// padding
$padding-80: 80px;
$padding-50: 50px;
$padding-40: 40px;
$padding-20: 20px;

// typeface
$typeface-store: 'Avenir Next', 'Helvetica', Arial, sans-serif;
$typeface-avenir: 'Avenir Next', 'Helvetica', Arial, sans-serif;
$typeface-montserrat: 'Montserrat', 'Meiryo UI', Arial, sans-serif;
$font-open-sans: 'Open Sans', sans-serif;

// break point (desktop first)
$min-mobile-break: 400px;
$mobile-break: 767px;
$tablet-break: 1023px;
$desktop-s-break: 1279px;
$desktop-l-break: 1439px;
$desktop-l1-break: 1445px;
$desktop-xl-break: 1899px;

// color
$color-white: #ffffff;
$blue: #2979ff;
$color-blue-200: #2d2d50;
$color-blue-150: #656aeb;
$color-purple-100:#604BBA;
$color-purple:#455af7;
// old - replace or remove later
$color-blue: #0d6db1;
$color-blue-new:#2979FF;
$color-white: #fff;
$color-background: #f5f5f5;
$color-border: #e0e0e0;
$color-dark-faded: #9e9e9e;
$color-dark: #424242;
$color-black-new: #435160;
$color-black: #000000;
$color-black-200: #19191b;
$color-black-100: #263238;
$color-positive: #0db13f;
$color-alert: #e00000;
$color-red: #ff0a43;
$color-pink: #ff7f9d;
$color-dark-transparent: rgba(58, 64, 75, 0.8);
$color-light-orange: #FBDAB6;
$color-grey-blue: #3a404b;
$color-royal-gold: #B6915D;
$color-banner-background:#fff1f0;
$color-banner-border:#ffa39e;

// new color
$color-blue-200 :#001449;
$color-blue-150 :#002566;
$color-blue-100: #1565c0;
$color-blue-80: #1e88e5;
$color-blue-60: #42a5f5;
$color-blue-40: #90caf9;
$color-blue-20: #e3f2fd;
$color-blue-10: #f2f7f9;

$color-gold-100: #846432;
$color-gold-80: #b6915d;
$color-gold-60: #eac18b;
$color-gold-40: #F4E0C5;
$color-gold-20: #F8ECDC;

$color-grey-200: #373737;
$color-grey-100: #102027;
$color-grey-95: #1f2935;
$color-grey-90: #464855;
$color-grey-80: #37474f;
$color-grey-60: #62727b;
$color-grey-40: #919CA2;
$color-grey-20: #B0B8BD;
$color-grey-10: #BCCED6;


$color-light-grey-100: #babdbe;
$color-light-grey-90: #6b6f82;
$color-light-grey-80: #eceff1;
$color-light-grey-70: #E1ECFF;
$color-light-grey-60: #F1F3F5;
$color-light-grey-40: #F8F9FA;
$color-light-grey-30: #f5f6fa;
$color-light-grey-30: #F3F5F7;
$color-light-grey-20: #F9FAFA;
$color-light-grey-10: #F2F5F7;

$color-white-border: #edeef4;

$color-red: #ff0000;
$color-green: #09b66d;

// nav height - if there's nav which has same size with below
$nav-height-60: 60px;
$nav-height-80: 80px;
$nav-height-120: 120px;