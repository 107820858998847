@import "../site/variables-v3";
@import "../site/common-v3";
@import "../site/utilities/icons-v3";
@import "../site/utilities/vw-calc";
@import "../site/footer-v3";
@import "../site/new-homepage/header";

.error-page-bg {
  background: url("/img/404-bg.jpg");
  padding-top: 80px;
  min-height: 100vh;
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
}

.http-error-pages-container {
  background-color: #FFF;

  &__page-404 {
    padding-top: vw(36px);
    display: flex;
    flex-direction: column;
    align-items: center;
    @media(max-width: $mobile-break) {
      padding: 10px;
    }
    & .title {
      font-family: "Open Sans";
      font-weight: 600;
      font-size: vw(36px);
      line-height: vw(49px);
      text-align: center;
      color: #435160;
      text-transform: none;
      margin-bottom: vw(25px);
      @media(max-width: $mobile-break) {
        font-size: 24px;
        line-height: 33px;
        margin-bottom: 16px;
      }
    }

    & .img-holder {
      width: vw(300px);
      margin-bottom: vw(40px);
      @media(max-width: $mobile-break) {
        width: 50%;
        margin-bottom: 20px;
      }
      & img{
        width: 100%;
        height: auto;
      }
    }

    & .desc{
      font-family: "Open Sans";
      font-weight: 600;
      font-size: vw(24px);
      line-height: vw(33px);
      text-align: center;
      color: #435160;
      margin-bottom: vw(38px);
      @media(max-width: $mobile-break) {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 18px;
      }
    }

    & .suggestion-boxes{
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      padding: 0 vw(168px);
      @media(max-width: $mobile-break) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 0 0 30px;
      }
      & .box{
        flex-basis: vw(538px);
        flex-grow: 1;
        margin-bottom: vw(26px);
        margin-right: vw(26px);
        padding: vw(24px);
        border: 1px solid #CFD5DE;
        border-radius: 10px;
        @media(max-width: $mobile-break) {
          flex-basis: 100%;
          width: 100%;
          flex-wrap: wrap;
          flex-grow: 1;
          padding: 10px;
          margin-bottom: 26px;
          margin-right: 0;
        }
        &:last-child{
          margin-right: 0;
          @media(max-width: $mobile-break) {
            margin-bottom: 0;
          }
        }
        & .box-title{
          font-family: "Open Sans";
          font-weight: 600;
          font-size: vw(20px);
          line-height: vw(27px);
          color: #435160;
          margin-bottom: vw(26px);
          @media(max-width: $mobile-break) {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 18px;
          }
        }
        & .list-holder{
          list-style: none;
          display: flex;
          flex-direction: column;
          padding: 0;
          margin: 0;
          & li{
            font-family: "Open Sans";
            font-weight: 600;
            font-size: vw(16px);
            line-height: vw(22px);
            color: #435160;
            margin-bottom: vw(14px);
            @media(max-width: $mobile-break) {
              font-size: 14px;
              line-height: 20px;
              margin-bottom: 14px;
            }
            & a{
              color: #2979FF;
              display: inline-flex;
              align-items: center;
            }
          }
        }
        & .list-holder-horizontal{
          flex-direction: row;
          justify-content: space-between;
          @media(max-width: $mobile-break) {
            flex-direction: column;
            justify-content: flex-start;
          }
        }
      }
    }

  }


  &__page-404-2 {
    padding-left: 126px;
    margin-bottom: 40px;
    @media(max-width: $mobile-break) {
      padding: 0;
    }
    .title {
      display: flex;
      @media(max-width: $mobile-break) {
        flex-direction: column-reverse;
      }
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 0 0 50%;
        @media(max-width: $mobile-break) {
          align-items: center;
          margin-top: 0;
        }
        h1 {
          color: $blue;
          font-weight: bold;
          font-size: 112px;
          margin-bottom: 20px;
          @media(max-width: $mobile-break) {
            font-size: 80px;
          }
        }
        h2 {
          font-size: 40px;
          @media(max-width: $mobile-break) {
            font-size: 28px;
            text-align: center;
            margin-bottom: 36px;
          }
        }
      }
      .image {
        flex: 0 0 50%;
        img {
          width: 100%;
        }
      }
    }
    .links {
      font-size: 20px;
      @media(max-width: $mobile-break) {
        text-align: center;
      }
      .heading {
        margin-top: 30px;
        font-size: 24px;
        font-weight: bold;
        @media(max-width: $mobile-break) {
          padding: 0 20px;
        }
      }
      .buttons {
        display: flex;
        margin-top: 8px;
        flex-wrap: wrap;
        @media(max-width: $mobile-break) {
          align-items: center;
          justify-content: center;
        }
        .button {
          cursor: pointer;
          color: $blue;
          font-size: 18px;
          border-radius: 8px;
          border: 1px solid $blue;
          padding: 8px 24px;
          margin-right: 24px;
          @media(max-width: $mobile-break) {
            font-size: 14px;
            margin-top: 10px;
            margin-right: 0;
          }
          .icon {
            width: 32px;
            height: 32px;
          }
        }
        .button-borderless {
          border: none;
          padding-left: 0;
          @media(max-width: $mobile-break) {
            padding: 0;
          }
        }
      }
    }
  }
}