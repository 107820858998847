// http://roomvustore.test/site/fonts/fontello/demo.html
/*
     Animation example, for spinners
*/
.animate-spin {
    -moz-animation: spin 2s infinite linear;
    -o-animation: spin 2s infinite linear;
    -webkit-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear;
    display: inline-block;
}
@-moz-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@-webkit-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@-o-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@-ms-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@font-face {
    font-family: 'rvicons-2020';
    font-display: swap;
    src: url('/fonts/fontello-rv-2020/font/rvicons-2020.eot?44392095');
    src: url('/fonts/fontello-rv-2020/font/rvicons-2020.eot?44392095#iefix') format('embedded-opentype'),
        url('/fonts/fontello-rv-2020/font/rvicons-2020.woff2?44392095') format('woff2'),
        url('/fonts/fontello-rv-2020/font/rvicons-2020.woff?44392095') format('woff'),
        url('/fonts/fontello-rv-2020/font/rvicons-2020.ttf?44392095') format('truetype'),
        url('/fonts/fontello-rv-2020/font/rvicons-2020.svg?44392095#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
@font-face {
    font-family: 'rvicons-2020';
    src: url('../font/rvicons-2020.svg?49553064#rvicons-2020') format('svg');
}
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
font-family: "rvicons-2020";
font-style: normal;
font-weight: normal;
speak: never;

display: inline-block;
text-decoration: inherit;
width: 1em;
margin-right: .2em;
text-align: center;
/* opacity: .8; */

/* For safety - reset parent styles, that can break glyph codes*/
font-variant: normal;
text-transform: none;

/* fix buttons height, for twitter bootstrap */
line-height: 1em;

/* Animation center compensation - margins should be symmetric */
/* remove if not needed */
margin-left: .2em;

/* you can be more comfortable with increased icons size */
/* font-size: 120%; */

/* Font smoothing. That was taken from TWBS */
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;

/* Uncomment for 3D effect */
/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-star:before { content: '\e800'; } /* '' */
.icon-star-half:before { content: '\e801'; } /* '' */
.icon-heart:before { content: '\e802'; } /* '' */
.icon-heart-empty:before { content: '\e803'; } /* '' */
.icon-search:before { content: '\e804'; } /* '' */
.icon-ok:before { content: '\e805'; } /* '' */
.icon-minus:before { content: '\e806'; } /* '' */
.icon-cancel:before { content: '\e807'; } /* '' */
.icon-left-dir:before { content: '\e808'; } /* '' */
.icon-up-dir:before { content: '\e809'; } /* '' */
.icon-down-dir:before { content: '\e80a'; } /* '' */
.icon-right-dir:before { content: '\e80b'; } /* '' */
.icon-down-open:before { content: '\e80c'; } /* '' */
.icon-left-open:before { content: '\e80d'; } /* '' */
.icon-right-open:before { content: '\e80e'; } /* '' */
.icon-up-open:before { content: '\e80f'; } /* '' */
.icon-resize-full:before { content: '\e810'; } /* '' */
.icon-resize-small:before { content: '\e811'; } /* '' */
.icon-down-big:before { content: '\e812'; } /* '' */
.icon-left-big:before { content: '\e813'; } /* '' */
.icon-right-big:before { content: '\e814'; } /* '' */
.icon-up-big:before { content: '\e815'; } /* '' */
.icon-chart-bar:before { content: '\e816'; } /* '' */
.icon-plus:before { content: '\e817'; } /* '' */
.icon-comment:before { content: '\e818'; } /* '' */
.icon-chat:before { content: '\e819'; } /* '' */
.icon-dot-3:before { content: '\e81a'; } /* '' */
.icon-down-open-big:before { content: '\e81b'; } /* '' */
.icon-left-open-big:before { content: '\e81c'; } /* '' */
.icon-right-open-big:before { content: '\e81d'; } /* '' */
.icon-up-open-big:before { content: '\e81e'; } /* '' */
.icon-ok-1:before { content: '\e81f'; } /* '' */
.icon-cancel-1:before { content: '\e820'; } /* '' */
.icon-resize-full-1:before { content: '\e821'; } /* '' */
.icon-resize-small-1:before { content: '\e822'; } /* '' */
.icon-down-open-1:before { content: '\e823'; } /* '' */
.icon-left-open-1:before { content: '\e824'; } /* '' */
.icon-right-open-1:before { content: '\e825'; } /* '' */
.icon-up-open-1:before { content: '\e826'; } /* '' */
.icon-down-open-mini:before { content: '\e827'; } /* '' */
.icon-up-open-mini:before { content: '\e828'; } /* '' */
.icon-right-open-mini:before { content: '\e829'; } /* '' */
.icon-left-open-mini:before { content: '\e82a'; } /* '' */
.icon-facebook-1:before { content: '\e82b'; } /* '' */
.icon-stumbleupon:before { content: '\e82c'; } /* '' */
.icon-flickr-circled:before { content: '\e82d'; } /* '' */
.icon-stumbleupon-circled:before { content: '\e82e'; } /* '' */
.icon-list:before { content: '\e82f'; } /* '' */
.icon-list-add:before { content: '\e830'; } /* '' */
.icon-paper-plane:before { content: '\e831'; } /* '' */
.icon-map:before { content: '\e832'; } /* '' */
.icon-location:before { content: '\e833'; } /* '' */
.icon-spin4:before { content: '\e834'; } /* '' */
.icon-spin3:before { content: '\e835'; } /* '' */
.icon-cog:before { content: '\e836'; } /* '' */
.icon-cog-alt:before { content: '\e837'; } /* '' */
.icon-resize-full-2:before { content: '\e838'; } /* '' */
.icon-resize-full-alt:before { content: '\e839'; } /* '' */
.icon-resize-small-2:before { content: '\e83a'; } /* '' */
.icon-resize-small-alt:before { content: '\e83b'; } /* '' */
.icon-resize-vertical:before { content: '\e83c'; } /* '' */
.icon-move:before { content: '\e83d'; } /* '' */
.icon-quote-left-alt:before { content: '\e83e'; } /* '' */
.icon-quote-right-alt:before { content: '\e83f'; } /* '' */
.icon-quote-right-1:before { content: '\e840'; } /* '' */
.icon-quote-left-1:before { content: '\e841'; } /* '' */
.icon-calendar-inv:before { content: '\e842'; } /* '' */
.icon-basket:before { content: '\e843'; } /* '' */
.icon-direction:before { content: '\e844'; } /* '' */
.icon-user:before { content: '\e845'; } /* '' */
.icon-cup:before { content: '\e846'; } /* '' */
.icon-trash:before { content: '\e847'; } /* '' */
.icon-quote:before { content: '\e848'; } /* '' */
.icon-basket-1:before { content: '\e849'; } /* '' */
.icon-quotation_icon:before { content: '\e84a'; } /* '' */
.icon-scroll_down:before { content: '\e84b'; } /* '' */
.icon-link:before { content: '\e84c'; } /* '' */
.icon-edit:before { content: '\e84d'; } /* '' */
.icon-pencil:before { content: '\e84e'; } /* '' */
.icon-attach:before { content: '\e84f'; } /* '' */
.icon-tag:before { content: '\e850'; } /* '' */
.icon-tags:before { content: '\e851'; } /* '' */
.icon-bookmark:before { content: '\e852'; } /* '' */
.icon-star-empty:before { content: '\e853'; } /* '' */
.icon-award:before { content: '\e854'; } /* '' */
.icon-award-empty:before { content: '\e855'; } /* '' */
.icon-closepage:before { content: '\e856'; } /* '' */
.icon-activity:before { content: '\e91a'; } /* '' */
.icon-airplay:before { content: '\e91b'; } /* '' */
.icon-alert-circle:before { content: '\e91c'; } /* '' */
.icon-alert-octagon:before { content: '\e91d'; } /* '' */
.icon-alert-triangle:before { content: '\e91e'; } /* '' */
.icon-align-center:before { content: '\e91f'; } /* '' */
.icon-align-justify:before { content: '\e920'; } /* '' */
.icon-align-left:before { content: '\e921'; } /* '' */
.icon-align-right:before { content: '\e922'; } /* '' */
.icon-anchor:before { content: '\e923'; } /* '' */
.icon-aperture:before { content: '\e924'; } /* '' */
.icon-apple-pay:before { content: '\e925'; } /* '' */
.icon-archive:before { content: '\e926'; } /* '' */
.icon-arrow-down-circle:before { content: '\e927'; } /* '' */
.icon-arrow-down-left:before { content: '\e928'; } /* '' */
.icon-arrow-down-right:before { content: '\e929'; } /* '' */
.icon-arrow-down:before { content: '\e92a'; } /* '' */
.icon-arrow-left-circle:before { content: '\e92b'; } /* '' */
.icon-arrow-left:before { content: '\e92c'; } /* '' */
.icon-arrow-right-circle:before { content: '\e92d'; } /* '' */
.icon-arrow-right:before { content: '\e92e'; } /* '' */
.icon-arrow-up-circle:before { content: '\e92f'; } /* '' */
.icon-arrow-up-left:before { content: '\e930'; } /* '' */
.icon-arrow-up-right:before { content: '\e931'; } /* '' */
.icon-arrow-up:before { content: '\e932'; } /* '' */
.icon-at-sign:before { content: '\e933'; } /* '' */
.icon-award-1:before { content: '\e934'; } /* '' */
.icon-bar-chart-2:before { content: '\e935'; } /* '' */
.icon-bar-chart:before { content: '\e936'; } /* '' */
.icon-battery-charging:before { content: '\e937'; } /* '' */
.icon-battery:before { content: '\e938'; } /* '' */
.icon-bell-off:before { content: '\e939'; } /* '' */
.icon-bell:before { content: '\e93a'; } /* '' */
.icon-bluetooth:before { content: '\e93b'; } /* '' */
.icon-bold:before { content: '\e93c'; } /* '' */
.icon-book-open:before { content: '\e93d'; } /* '' */
.icon-book:before { content: '\e93e'; } /* '' */
.icon-bookmark-1:before { content: '\e93f'; } /* '' */
.icon-box:before { content: '\e940'; } /* '' */
.icon-briefcase:before { content: '\e941'; } /* '' */
.icon-calendar:before { content: '\e942'; } /* '' */
.icon-camera-off:before { content: '\e943'; } /* '' */
.icon-camera:before { content: '\e944'; } /* '' */
.icon-cast:before { content: '\e945'; } /* '' */
.icon-cc-amazon-pay:before { content: '\e946'; } /* '' */
.icon-cc-amex:before { content: '\e947'; } /* '' */
.icon-cc-apple-pay:before { content: '\e948'; } /* '' */
.icon-cc-diners-club:before { content: '\e949'; } /* '' */
.icon-cc-discover:before { content: '\e94a'; } /* '' */
.icon-cc-mastercard-1:before { content: '\e94b'; } /* '' */
.icon-cc-paypal:before { content: '\e94c'; } /* '' */
.icon-cc-stripe:before { content: '\e94d'; } /* '' */
.icon-cc-visa:before { content: '\e94e'; } /* '' */
.icon-check-circle:before { content: '\e94f'; } /* '' */
.icon-check-square:before { content: '\e950'; } /* '' */
.icon-check:before { content: '\e951'; } /* '' */
.icon-chevron-down:before { content: '\e952'; } /* '' */
.icon-chevron-left:before { content: '\e953'; } /* '' */
.icon-chevron-right:before { content: '\e954'; } /* '' */
.icon-chevron-up:before { content: '\e955'; } /* '' */
.icon-chevrons-down:before { content: '\e956'; } /* '' */
.icon-chevrons-left:before { content: '\e957'; } /* '' */
.icon-chevrons-right:before { content: '\e958'; } /* '' */
.icon-chevrons-up:before { content: '\e959'; } /* '' */
.icon-chrome:before { content: '\e95a'; } /* '' */
.icon-circle:before { content: '\e95b'; } /* '' */
.icon-clipboard:before { content: '\e95c'; } /* '' */
.icon-clock:before { content: '\e95d'; } /* '' */
.icon-cloud-drizzle:before { content: '\e95e'; } /* '' */
.icon-cloud-lightning:before { content: '\e95f'; } /* '' */
.icon-cloud-off:before { content: '\e960'; } /* '' */
.icon-cloud-rain:before { content: '\e961'; } /* '' */
.icon-cloud-snow:before { content: '\e962'; } /* '' */
.icon-cloud:before { content: '\e963'; } /* '' */
.icon-code:before { content: '\e964'; } /* '' */
.icon-coffee:before { content: '\e965'; } /* '' */
.icon-columns:before { content: '\e966'; } /* '' */
.icon-compass:before { content: '\e967'; } /* '' */
.icon-copy:before { content: '\e968'; } /* '' */
.icon-corner-down-left:before { content: '\e969'; } /* '' */
.icon-corner-down-right:before { content: '\e96a'; } /* '' */
.icon-corner-left-down:before { content: '\e96b'; } /* '' */
.icon-corner-left-up:before { content: '\e96c'; } /* '' */
.icon-corner-right-down:before { content: '\e96d'; } /* '' */
.icon-corner-right-up:before { content: '\e96e'; } /* '' */
.icon-corner-up-left:before { content: '\e96f'; } /* '' */
.icon-corner-up-right:before { content: '\e970'; } /* '' */
.icon-cpu:before { content: '\e971'; } /* '' */
.icon-credit-card-copy:before { content: '\e972'; } /* '' */
.icon-credit-card:before { content: '\e973'; } /* '' */
.icon-crop:before { content: '\e974'; } /* '' */
.icon-crosshair:before { content: '\e975'; } /* '' */
.icon-database:before { content: '\e976'; } /* '' */
.icon-delete:before { content: '\e977'; } /* '' */
.icon-disc:before { content: '\e978'; } /* '' */
.icon-dollar-sign:before { content: '\e979'; } /* '' */
.icon-download-cloud:before { content: '\e97a'; } /* '' */
.icon-download:before { content: '\e97b'; } /* '' */
.icon-droplet-solid:before { content: '\e97c'; } /* '' */
.icon-droplet:before { content: '\e97d'; } /* '' */
.icon-edit-2:before { content: '\e97e'; } /* '' */
.icon-edit-3:before { content: '\e97f'; } /* '' */
.icon-edit-1:before { content: '\e980'; } /* '' */
.icon-external-link:before { content: '\e981'; } /* '' */
.icon-eye-off:before { content: '\e982'; } /* '' */
.icon-eye:before { content: '\e983'; } /* '' */
.icon-facebook-f:before { content: '\e984'; } /* '' */
.icon-facebook-feather:before { content: '\e985'; } /* '' */
.icon-facebook-square:before { content: '\e986'; } /* '' */
.icon-facebook-2:before { content: '\e987'; } /* '' */
.icon-fast-forward:before { content: '\e988'; } /* '' */
.icon-feather:before { content: '\e989'; } /* '' */
.icon-file-minus:before { content: '\e98a'; } /* '' */
.icon-file-plus:before { content: '\e98b'; } /* '' */
.icon-file-text:before { content: '\e98c'; } /* '' */
.icon-file:before { content: '\e98d'; } /* '' */
.icon-film:before { content: '\e98e'; } /* '' */
.icon-filter-solid:before { content: '\e98f'; } /* '' */
.icon-filter:before { content: '\e990'; } /* '' */
.icon-folder-minus:before { content: '\e992'; } /* '' */
.icon-folder-plus:before { content: '\e993'; } /* '' */
.icon-folder:before { content: '\e994'; } /* '' */
.icon-frown-1:before { content: '\e995'; } /* '' */
.icon-gift:before { content: '\e996'; } /* '' */
.icon-globe:before { content: '\e997'; } /* '' */
.icon-google-pay:before { content: '\e998'; } /* '' */
.icon-grid:before { content: '\e999'; } /* '' */
.icon-hard-drive:before { content: '\e99a'; } /* '' */
.icon-hash:before { content: '\e99b'; } /* '' */
.icon-headphones:before { content: '\e99c'; } /* '' */
.icon-heart-solid:before { content: '\e99d'; } /* '' */
.icon-heart-1:before { content: '\e99e'; } /* '' */
.icon-help-circle:before { content: '\e99f'; } /* '' */
.icon-hexagon:before { content: '\e9a0'; } /* '' */
.icon-home:before { content: '\e9a1'; } /* '' */
.icon-image:before { content: '\e9a2'; } /* '' */
.icon-inbox:before { content: '\e9a3'; } /* '' */
.icon-info:before { content: '\e9a4'; } /* '' */
.icon-instagram-feather:before { content: '\e9a5'; } /* '' */
.icon-instagram-square:before { content: '\e9a6'; } /* '' */
.icon-italic:before { content: '\e9a8'; } /* '' */
.icon-key:before { content: '\e9a9'; } /* '' */
.icon-layers:before { content: '\e9aa'; } /* '' */
.icon-layout:before { content: '\e9ab'; } /* '' */
.icon-life-buoy:before { content: '\e9ac'; } /* '' */
.icon-link-2:before { content: '\e9ad'; } /* '' */
.icon-link-1:before { content: '\e9ae'; } /* '' */
.icon-linkedin-feather:before { content: '\e9af'; } /* '' */
.icon-list-1:before { content: '\e9b0'; } /* '' */
.icon-loader:before { content: '\e9b1'; } /* '' */
.icon-lock:before { content: '\e9b2'; } /* '' */
.icon-log-in:before { content: '\e9b3'; } /* '' */
.icon-log-out:before { content: '\e9b4'; } /* '' */
.icon-mail:before { content: '\e9b5'; } /* '' */
.icon-map-pin:before { content: '\e9b6'; } /* '' */
.icon-map-1:before { content: '\e9b7'; } /* '' */
.icon-maximize-2:before { content: '\e9b8'; } /* '' */
.icon-maximize:before { content: '\e9b9'; } /* '' */
.icon-meh-1:before { content: '\e9ba'; } /* '' */
.icon-menu-1:before { content: '\e9bb'; } /* '' */
.icon-message-circle:before { content: '\e9bc'; } /* '' */
.icon-message-square:before { content: '\e9bd'; } /* '' */
.icon-mic:before { content: '\e9bf'; } /* '' */
.icon-minimize-2:before { content: '\e9c0'; } /* '' */
.icon-minimize:before { content: '\e9c1'; } /* '' */
.icon-minus-circle:before { content: '\e9c2'; } /* '' */
.icon-minus-square:before { content: '\e9c3'; } /* '' */
.icon-minus-1:before { content: '\e9c4'; } /* '' */
.icon-monitor:before { content: '\e9c5'; } /* '' */
.icon-moon:before { content: '\e9c6'; } /* '' */
.icon-more-horizontal:before { content: '\e9c7'; } /* '' */
.icon-more-vertical:before { content: '\e9c8'; } /* '' */
.icon-mouse-pointer:before { content: '\e9c9'; } /* '' */
.icon-move-1:before { content: '\e9ca'; } /* '' */
.icon-music:before { content: '\e9cb'; } /* '' */
.icon-navigation-2:before { content: '\e9cc'; } /* '' */
.icon-navigation:before { content: '\e9cd'; } /* '' */
.icon-octagon:before { content: '\e9ce'; } /* '' */
.icon-package:before { content: '\e9cf'; } /* '' */
.icon-paperclip:before { content: '\e9d0'; } /* '' */
.icon-pause-circle:before { content: '\e9d1'; } /* '' */
.icon-pause:before { content: '\e9d2'; } /* '' */
.icon-pen-tool:before { content: '\e9d3'; } /* '' */
.icon-percent:before { content: '\e9d4'; } /* '' */
.icon-phone-call:before { content: '\e9d5'; } /* '' */
.icon-phone-forwarded:before { content: '\e9d6'; } /* '' */
.icon-phone-incoming:before { content: '\e9d7'; } /* '' */
.icon-phone-missed:before { content: '\e9d8'; } /* '' */
.icon-phone-off:before { content: '\e9d9'; } /* '' */
.icon-phone-outgoing:before { content: '\e9da'; } /* '' */
.icon-phone:before { content: '\e9db'; } /* '' */
.icon-pie-chart:before { content: '\e9dc'; } /* '' */
.icon-play-circle:before { content: '\e9dd'; } /* '' */
.icon-play:before { content: '\e9de'; } /* '' */
.icon-plus-circle:before { content: '\e9df'; } /* '' */
.icon-plus-square:before { content: '\e9e0'; } /* '' */
.icon-plus-1:before { content: '\e9e1'; } /* '' */
.icon-pocket:before { content: '\e9e2'; } /* '' */
.icon-power:before { content: '\e9e3'; } /* '' */
.icon-printer:before { content: '\e9e4'; } /* '' */
.icon-radio:before { content: '\e9e5'; } /* '' */
.icon-refresh-ccw:before { content: '\e9e6'; } /* '' */
.icon-refresh-cw:before { content: '\e9e7'; } /* '' */
.icon-repeat:before { content: '\e9e8'; } /* '' */
.icon-rewind:before { content: '\e9e9'; } /* '' */
.icon-rotate-ccw:before { content: '\e9ea'; } /* '' */
.icon-rotate-cw:before { content: '\e9eb'; } /* '' */
.icon-rss:before { content: '\e9ec'; } /* '' */
.icon-save:before { content: '\e9ed'; } /* '' */
.icon-scissors:before { content: '\e9ee'; } /* '' */
.icon-search-1:before { content: '\e9ef'; } /* '' */
.icon-send:before { content: '\e9f0'; } /* '' */
.icon-server:before { content: '\e9f1'; } /* '' */
.icon-settings:before { content: '\e9f2'; } /* '' */
.icon-share-2:before { content: '\e9f3'; } /* '' */
.icon-share-1:before { content: '\e9f4'; } /* '' */
.icon-shield-off:before { content: '\e9f5'; } /* '' */
.icon-shield:before { content: '\e9f6'; } /* '' */
.icon-shopping-bag:before { content: '\e9f7'; } /* '' */
.icon-shopping-cart:before { content: '\e9f8'; } /* '' */
.icon-shuffle:before { content: '\e9f9'; } /* '' */
.icon-sidebar:before { content: '\e9fa'; } /* '' */
.icon-skip-back:before { content: '\e9fb'; } /* '' */
.icon-skip-forward:before { content: '\e9fc'; } /* '' */
.icon-slash:before { content: '\e9fd'; } /* '' */
.icon-sliders-1:before { content: '\e9fe'; } /* '' */
.icon-smartphone:before { content: '\e9ff'; } /* '' */
.icon-smile-1:before { content: '\ea00'; } /* '' */
.icon-speaker:before { content: '\ea01'; } /* '' */
.icon-square:before { content: '\ea02'; } /* '' */
.icon-star-2:before { content: '\ea03'; } /* '' */
.icon-star-1:before { content: '\ea04'; } /* '' */
.icon-stop-circle:before { content: '\ea05'; } /* '' */
.icon-stripe-s:before { content: '\ea06'; } /* '' */
.icon-stripe:before { content: '\ea07'; } /* '' */
.icon-sun:before { content: '\ea08'; } /* '' */
.icon-sunrise:before { content: '\ea09'; } /* '' */
.icon-sunset:before { content: '\ea0a'; } /* '' */
.icon-tablet:before { content: '\ea0b'; } /* '' */
.icon-tag-1:before { content: '\ea0c'; } /* '' */
.icon-target:before { content: '\ea0d'; } /* '' */
.icon-terminal:before { content: '\ea0e'; } /* '' */
.icon-thermometer:before { content: '\ea0f'; } /* '' */
.icon-thumbs-down:before { content: '\ea10'; } /* '' */
.icon-thumbs-up:before { content: '\ea11'; } /* '' */
.icon-toggle-left:before { content: '\ea12'; } /* '' */
.icon-toggle-right:before { content: '\ea13'; } /* '' */
.icon-tool:before { content: '\ea14'; } /* '' */
.icon-trash-2:before { content: '\ea15'; } /* '' */
.icon-trash-1:before { content: '\ea16'; } /* '' */
.icon-trending-down:before { content: '\ea17'; } /* '' */
.icon-trending-up:before { content: '\ea18'; } /* '' */
.icon-triangle:before { content: '\ea19'; } /* '' */
.icon-truck:before { content: '\ea1a'; } /* '' */
.icon-tv:before { content: '\ea1b'; } /* '' */
.icon-twitter-feather:before { content: '\ea1c'; } /* '' */
.icon-twitter-square:before { content: '\ea1d'; } /* '' */
.icon-twitter-2:before { content: '\ea1e'; } /* '' */
.icon-umbrella:before { content: '\ea1f'; } /* '' */
.icon-type:before { content: '\ea20'; } /* '' */
.icon-underline:before { content: '\ea21'; } /* '' */
.icon-upload-cloud:before { content: '\ea23'; } /* '' */
.icon-upload:before { content: '\ea24'; } /* '' */
.icon-user-check:before { content: '\ea25'; } /* '' */
.icon-user-minus:before { content: '\ea26'; } /* '' */
.icon-user-plus:before { content: '\ea27'; } /* '' */
.icon-user-x:before { content: '\ea28'; } /* '' */
.icon-user-1:before { content: '\ea29'; } /* '' */
.icon-users:before { content: '\ea2a'; } /* '' */
.icon-video-off:before { content: '\ea2b'; } /* '' */
.icon-video-solid:before { content: '\ea2c'; } /* '' */
.icon-video:before { content: '\ea2d'; } /* '' */
.icon-voicemail:before { content: '\ea2e'; } /* '' */
.icon-volume-1:before { content: '\ea2f'; } /* '' */
.icon-volume-2:before { content: '\ea30'; } /* '' */
.icon-volume-x:before { content: '\ea31'; } /* '' */
.icon-volume:before { content: '\ea32'; } /* '' */
.icon-whatsapp-square:before { content: '\ea35'; } /* '' */
.icon-whatsapp:before { content: '\ea36'; } /* '' */
.icon-wifi-off:before { content: '\ea37'; } /* '' */
.icon-wifi:before { content: '\ea38'; } /* '' */
.icon-wind:before { content: '\ea39'; } /* '' */
.icon-x-circle:before { content: '\ea3a'; } /* '' */
.icon-x-octagon:before { content: '\ea3b'; } /* '' */
.icon-x-square:before { content: '\ea3c'; } /* '' */
.icon-x:before { content: '\ea3d'; } /* '' */
.icon-youtube-square:before { content: '\ea3e'; } /* '' */
.icon-zap-off:before { content: '\ea40'; } /* '' */
.icon-zap:before { content: '\ea41'; } /* '' */
.icon-zoom-in:before { content: '\ea42'; } /* '' */
.icon-zoom-out:before { content: '\ea43'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-pinterest-circled:before { content: '\f0d2'; } /* '' */
.icon-pinterest-squared:before { content: '\f0d3'; } /* '' */
.icon-mail-alt:before { content: '\f0e0'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-angle-double-left:before { content: '\f100'; } /* '' */
.icon-angle-double-right:before { content: '\f101'; } /* '' */
.icon-angle-double-up:before { content: '\f102'; } /* '' */
.icon-angle-double-down:before { content: '\f103'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-quote-left:before { content: '\f10d'; } /* '' */
.icon-quote-right:before { content: '\f10e'; } /* '' */
.icon-smile:before { content: '\f118'; } /* '' */
.icon-frown:before { content: '\f119'; } /* '' */
.icon-meh:before { content: '\f11a'; } /* '' */
.icon-star-half-alt:before { content: '\f123'; } /* '' */
.icon-unlink:before { content: '\f127'; } /* '' */
.icon-thumbs-up-alt:before { content: '\f164'; } /* '' */
.icon-thumbs-down-alt:before { content: '\f165'; } /* '' */
.icon-youtube:before { content: '\f167'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
.icon-instagram-1:before { content: '\f16d'; } /* '' */
.icon-skype:before { content: '\f17e'; } /* '' */
.icon-weibo:before { content: '\f18a'; } /* '' */
.icon-renren:before { content: '\f18b'; } /* '' */
.icon-slack:before { content: '\f198'; } /* '' */
.icon-google:before { content: '\f1a0'; } /* '' */
.icon-wechat:before { content: '\f1d7'; } /* '' */
.icon-sliders:before { content: '\f1de'; } /* '' */
.icon-share:before { content: '\f1e0'; } /* '' */
.icon-cc-discover-1:before { content: '\f1f2'; } /* '' */
.icon-chart-area:before { content: '\f1fe'; } /* '' */
.icon-toggle-off:before { content: '\f204'; } /* '' */
.icon-toggle-on:before { content: '\f205'; } /* '' */
.icon-facebook-official:before { content: '\f230'; } /* '' */
.icon-pinterest:before { content: '\f231'; } /* '' */
.icon-credit-card-alt:before { content: '\f283'; } /* '' */
.icon-snapchat:before { content: '\f2ab'; } /* '' */
.icon-snapchat-ghost:before { content: '\f2ac'; } /* '' */
.icon-snapchat-square:before { content: '\f2ad'; } /* '' */
.icon-flickr:before { content: '\f303'; } /* '' */
.icon-twitter-squared:before { content: '\f304'; } /* '' */
.icon-vimeo:before { content: '\f306'; } /* '' */
.icon-vimeo-circled:before { content: '\f307'; } /* '' */
.icon-facebook-squared:before { content: '\f308'; } /* '' */
.icon-twitter-1:before { content: '\f309'; } /* '' */
.icon-twitter-circled:before { content: '\f30a'; } /* '' */
.icon-linkedin-squared:before { content: '\f30c'; } /* '' */
.icon-facebook-circled:before { content: '\f30d'; } /* '' */
.icon-gplus:before { content: '\f30f'; } /* '' */
.icon-gplus-circled:before { content: '\f310'; } /* '' */
.icon-pinterest-circled-1:before { content: '\f313'; } /* '' */
.icon-tumblr:before { content: '\f315'; } /* '' */
.icon-tumblr-circled:before { content: '\f316'; } /* '' */
.icon-linkedin-circled:before { content: '\f319'; } /* '' */
.icon-dribbble:before { content: '\f31b'; } /* '' */
.icon-dribbble-circled:before { content: '\f31c'; } /* '' */
.icon-instagram:before { content: '\f31e'; } /* '' */
.icon-instagram-filled:before { content: '\f31f'; } /* '' */
.icon-instagram-2:before { content: '\f32d'; } /* '' */
.icon-renren-1:before { content: '\f33c'; } /* '' */
.icon-sina-weibo:before { content: '\f33f'; } /* '' */